import $ from 'jquery';
import 'bootstrap';
import PerfectScrollbar from 'perfect-scrollbar';
import feather from 'feather-icons';
import 'select2';
import Swal from 'sweetalert2';
import 'datatables.net';
import 'datatables.net-dt';
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt';
import './dashforge.aside';

import Echo from "laravel-echo";
import io from 'socket.io-client';

window.io = io;
window.Swal = Swal;

window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: window.ECHO_HOSTNAME + ':' + window.ECHO_PORT
});

// Get the country & hidden input
var from = document.getElementById("from");
var active_report = document.getElementById("active-report");
var jpn_report = document.getElementById("jpn-report");
var uk_report = document.getElementById("uk-report");

// When the user clicks on <span> (x), close the modal
if (jpn_report) {
    jpn_report.onclick = function () {
        active_report.classList.remove("flag-icon-gb");
        active_report.className += " flag-icon-jp";
        from.value = "JPN";
        uk_report.classList.remove("active");
        jpn_report.classList.add("active");
    }
}
if (uk_report) {
    uk_report.onclick = function () {
        active_report.classList.remove("flag-icon-jp");
        active_report.className += " flag-icon-gb";
        from.value = "UK";
        jpn_report.classList.remove("active");
        uk_report.classList.add("active");
    }
}

window.$ = window.jQuery = $

$(function () {

    'use strict'

    feather.replace();
    ////////// NAVBAR //////////


    let navbarMenuEl = document.querySelector('#navbarMenu')
    if (navbarMenuEl) {
        // Initialize PerfectScrollbar of navbar menu for mobile only
        if (window.innerWidth <= 991) {
            const psNavbar = new PerfectScrollbar('#navbarMenu', {
                suppressScrollX: true
            });
        }

        // Showing sub-menu of active menu on navbar when mobile
        function showNavbarActiveSub() {
            if (window.innerWidth <= 991) {
                $('#navbarMenu .active').addClass('show');
            } else {
                $('#navbarMenu .active').removeClass('show');
            }
        }

        showNavbarActiveSub()
        $(window).resize(function () {
            showNavbarActiveSub()
        })

        // Initialize backdrop for overlay purpose
        $('body').append('<div class="backdrop"></div>');


        // Showing sub menu of navbar menu while hiding other siblings
        $('.navbar-menu .with-sub .nav-link').on('click', function (e) {
            e.preventDefault();
            $(this).parent().toggleClass('show');
            $(this).parent().siblings().removeClass('show');

            if (window.innerWidth <= 991) {
                psNavbar.update();
            }
        })

        // Closing dropdown menu of navbar menu
        $(document).on('click touchstart', function (e) {
            e.stopPropagation();

            // closing nav sub menu of header when clicking outside of it
            if (window.matchMedia('(min-width: 992px)').matches) {
                var navTarg = $(e.target).closest('.navbar-menu .nav-item').length;
                if (!navTarg) {
                    $('.navbar-header .show').removeClass('show');
                }
            }
        })

        $('#mainMenuClose').on('click', function (e) {
            e.preventDefault();
            $('body').removeClass('navbar-nav-show');
        });

        $('#sidebarMenuOpen').on('click', function (e) {
            e.preventDefault();
            $('body').addClass('sidebar-show');
        })

        // Navbar Search
        $('#navbarSearch').on('click', function (e) {
            e.preventDefault();
            $('.navbar-search').addClass('visible');
            $('.backdrop').addClass('show');
        })

        $('#navbarSearchClose').on('click', function (e) {
            e.preventDefault();
            $('.navbar-search').removeClass('visible');
            $('.backdrop').removeClass('show');
        })
    }



    ////////// SIDEBAR //////////

    // Initialize PerfectScrollbar for sidebar menu
    if ($('#sidebarMenu').length) {
        const psSidebar = new PerfectScrollbar('#sidebarMenu', {
            suppressScrollX: true
        });


        // Showing sub menu in sidebar
        $('.sidebar-nav .with-sub').on('click', function (e) {
            e.preventDefault();
            $(this).parent().toggleClass('show');

            psSidebar.update();
        })
    }


    $('#mainMenuOpen').on('click touchstart', function (e) {
        e.preventDefault();
        $('body').addClass('navbar-nav-show');
    })

    $('#sidebarMenuClose').on('click', function (e) {
        e.preventDefault();
        $('body').removeClass('sidebar-show');
    })

    // hide sidebar when clicking outside of it
    $(document).on('click touchstart', function (e) {
        e.stopPropagation();

        // closing of sidebar menu when clicking outside of it
        if (!$(e.target).closest('.burger-menu').length) {
            var sb = $(e.target).closest('.sidebar').length;
            var nb = $(e.target).closest('.navbar-menu-wrapper').length;
            if (!sb && !nb) {
                if ($('body').hasClass('navbar-nav-show')) {
                    $('body').removeClass('navbar-nav-show');
                } else {
                    $('body').removeClass('sidebar-show');
                }
            }
        }
    });

})
